import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'
import Emoji from './emoji'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {                 
            github
            email
          }
        }
      }
    `}
    render={data => (
      <footer className='footer center has-background-light'>
        <div className='content has-text-centered'>
          <Emoji emoji='☕' />          
          <p className='is-size-5'>
          {data.site.siteMetadata.email}
          </p>
        </div>
      </footer>
    )}
  />
)

export default Footer
