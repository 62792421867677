import React from 'react';
import './style.scss';
import Navbar from './navbar';
import layerslogo from '../images/layers.png';

const Header = ({ siteTitle }) => (
	<section className="hero gradientBg is-fullheight-with-navbar">
		<Navbar />
		<div className="hero-body">
			<div className="container center">
				<article className="media">										
                <figure className="is-left">
						<span className="icon is-large">
							<img src={layerslogo} alt="ascend-logo" />
						</span>
					</figure>
					<div className="media-content">
						<div className="content has-text-centered">
							<h1 className="is-uppercase is-size-1 has-text-white">
								Hello from
							</h1>
							<p className="subtitle has-text-white is-size-3">
                            Ascend Technology																
							</p>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
);

export default Header;
